import React from 'react';

import {
  Link
} from 'react-router-dom';

const ComingSoon = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson_hopeworks-web_spring-2019.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Currently Processing</h2>
        <h4 className="text-gray-300 text-2xl italic">The documentation for this project will be coming soon.</h4>
      </div>
    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Featured Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-creatures" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg" alt="Literary Creatures - Philly Fringe Fest 2018 - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Creatures</div>
              <p className="italic font-bold text-gray-500 mb-2">September 2018, Philly Fringe Fest</p>
              <p className="text-gray-700 text-base mb-2">
                An improvisational, sensory performance piece featuring one <span className="italic">A. Ti Nyalien</span>.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/tesla-spacex" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_typewriter-poetry_free-poetry_tesla-space-x_valentines-day-2018.jpg" alt="Free Poetry (Tesla, SpaceX) - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry: Tesla, SpaceX</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2018, Tesla &amp; SpaceX</p>
              <p className="text-gray-700 text-base mb-2">
                Writing <span className="italic">Typewriter Poetry</span> for Tesla &amp; SpaceX employees on Valentine's Day.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/princeton" className="work-card-link"></Link>
            <img style={{objectPosition: "top"}} src="./img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Beyond Words - Princeton Public Library's Annual Gala - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry: Beyond Words Gala</div>
              <p className="italic font-bold text-gray-500 mb-2">December 2014, Princeton, NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Performing <span className="italic">Typewriter Poetry</span> during Princeton Public Library's annual fundraiser.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default ComingSoon;
