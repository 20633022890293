import React from 'react';

import {
  Link
} from 'react-router-dom';

const About = () => (
  <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">
    <div className="my-8 text-gray-700 text-2xl lg:text-1xl hyphens-soft">
      <p className="my-10 italic text-gray-500 text-center">I am just a human trying to make the most of this transient dream we call life</p>
      <p className="my-8"><span className="uppercase font-bold"><span className="dropcap">M</span>y name is Billimarie Lubi&shy;ano Robinson.</span> Born &amp; raised in the San Fernando Valley (Los Angeles, Cali&shy;fornia), I grad&shy;uated from Cal State Northridge with a dual-degree in Poetry &amp; Multi&shy;media. As a student, I worked all four years as a graphic designer &amp; web developer to pay off my tuition, &amp; lived rent-free with my parents instead of at the dorms. This meant I was free from student loans&mdash;&amp; free to pursue my dreams of becoming a wandering artist after graduation.</p>
      <p className="my-8">A year after receiving my BA, I took off wandering around Los Angeles with my 1950's pink Royal typewriter. I started off typing Free Poetry for people as a street per&shy;former at the <a href="https://www.1111acc.org/" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">Canoga Park Art Walk</span></a>, various Venice Beach art parties, &amp; at the Santa Monica Promenade for tourists. I went on to type over hun&shy;dreds of poems from 2011 to 2018, eventually fulfilling my dream of travel&shy;ing around the world to perform Type&shy;writer Poetry. I typed for strangers in museums, at farmer's markets, art events, &amp; on popular street corners in Asheville (Oregon), Hawaii, &amp; NOLA. I re&shy;ceived offers to type for insti&shy;tutions like the <span className="underline text-gray-600 hover:text-grey-900">Staten Island Museum</span>, the <Link to="/free-poetry/princeton"><span className="underline text-gray-600 hover:text-grey-900">Princeton Public Libr&shy;ary's annual fundraising gala</span></Link>, &amp; even at <Link to="/free-poetry/teslas-pacex"><span className="underline text-gray-600 hover:text-grey-900">Tesla / SpaceX for Val&shy;entine's Day</span></Link>.</p>
      <p className="my-8">Outside of my poetry ad&shy;ventures, I have <span className="font-bold">over 13 years of experience in tech as an engineer, researcher, &amp; designer</span>. I have worked for small busi&shy;nesses, agencies, non&shy;profits, &amp; start-ups such as Message Agency, Hope&shy;works, Coded by Kids, 2U, &amp; Earthly Body. My strengths include enhancing client communications by breaking down complex technical solutions in easy-to-understand language for project stakeholders, as well as providing mentorship &amp; growth for better team engage&shy;ment. For more about my professional tech experience, please <a href="mailto:work@billimarie.com?subject=CV" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">email me for a copy of my CV/resume</span></a>.</p>
      <p className="my-8">When not working on a tech or traveling project, I enjoy reading, writing, painting, &amp; making music at my home office &amp; studio. I remain faithfully committed to my typewriter. As an artist, my practice is cyclical, elliptical, &amp; seasonal. I cycle through a variety of project-based mediums: poetry, creative non-fiction, lyric essay; participatory performance art; teaching, mentorship. My anchors are pre-colonial stories, spirituality, & myths; movement, migration, & action; sustainability & Earth-first play. Recurring interests include cultivating mindful patterns, hacking &amp; destroying systems, reckless wandering, playful participation, &amp; various modes of compassionate anarchy.</p>
      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>
      <p className="my-8">Going into the next decade (2020), I have decided to <span className="underline text-gray-600 hover:text-grey-900">quietly go dark on social media</span>. You can still find me on <a href="https://www.github.com/billimarie" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">GitHub</span></a>, <a href="https://www.linkedin.com/in/billimarie" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">LinkedIn</span></a>, &amp; <a href="https://www.twitter.com/typewritepoetry" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">Twitter</span></a>&mdash;&amp; of course, the in-person experience will always available for sharing tea or catching up over a telephone call.</p>
    </div>
  </div>
);

export default About;
