import React from 'react';

import {
  Link
} from 'react-router-dom';

const TeslaSpaceX = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./../img/billimarie-lubiano-robinson_typewriter-poetry_free-poetry_tesla-space-x_valentines-day-2018.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Free Poetry: Tesla, SpaceX</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">February 2018 &bull; Los Angeles, CA</h3>
        <h4 className="text-gray-300 text-2xl italic">Free Poetry for Valentine's Day</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">T</span>his past Valentine's Day, I</span> was invited by Shawna of SauceLA to type Free Poetry at Tesla + SpaceX for the holiday. Immediately, I accepted. SauceLA are one of my favorite event producers in all of Los Angeles. They’re the wonderful folks who produce Artisanal LA, an amazing festival I’ve typed at several times before (see: dressed as a Magritte-inspired Bureaucrat, passing out Free Poetry stickers, and making friends with creative science projects).</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Performing Typewriter Poetry on the grounds of Tesla and SpaceX challenged me to step outside of my normal routine and figure out ways to improve my existing setup. I found a wonderful rustic chalkboard at JoAnne’s the night before, which had a better feel than my usual Free Poetry cardboard sign (often taken out of the recycling bin and scribbled on in Sharpie).</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Once the chalkboard sign fell into place, I knew I had to update the rest of the elements: that meant two tiny tables covered with white linen (thanks, mom!), a matching rustic jewelry box to house paper, pens, and other materials (an old gift from an old friend), and of course, a beloved thin green rug that lives in the garage of my parents’ house.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The folks at Tesla and SpaceX were wonderful. I got to hear incredible stories about which building houses what machinery–and what said machinery was designed to do. As a front-end web developer, I connected deeply with people who talked about their UI and UX battles with their internal systems. Everyone was extremely generous with their tips–I even received my first Typewriter Poetry Venmo donation!–and I got to type a lot of poems for several lucky wives, girlfriends, and fiancés.</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full" style={{height:500 + "px"}}>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_tesla-space-x_norm_valentines-day_free-poetry_02-13-2018.jpeg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_free-poetry_tesla-space-x_valentines-day-2018.jpg" alt="Typewriter Poetry - Free Poetry - Valentine's Day - Tesla, Space X - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_tesla-space-x_valentines-day_07_free-poetry_02-13-2018.jpg" alt="Free Poetry" />
      </div>

    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-carnival" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Literary Carnival - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Carnival</div>
              <p className="italic font-bold text-gray-500 mb-2">August 2013, the Newer York</p>
              <p className="text-gray-700 text-base mb-2">
                Tarot, drag, &amp; <span className="italic">Typewriter Poetry</span> before the show.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2015)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
              <p className="text-gray-700 text-base mb-2">
                Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/princeton" className="work-card-link"></Link>
            <img style={{objectPosition: "top"}} src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Beyond Words - Princeton Public Library's Annual Gala - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry: Beyond Words Gala</div>
              <p className="italic font-bold text-gray-500 mb-2">December 2014, Princeton, NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Performing <span className="italic">Typewriter Poetry</span> during Princeton Public Library's annual fundraiser.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default TeslaSpaceX;
