import React from 'react';

import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className='w-full text-center border-t border-grey lg:p-4 fixed bg-gray-100 z-10 bottom-0'>
    <ul className="icon-nav block lg:hidden flex justify-center">
      <li className="flex-auto text-gray-700 text-center px-4 py-2 m-2 flex justify-center">
        <Link to="/" data-tooltip="Home">
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-haspopup="true" role="button" tabIndex="0">
            <path className="text-gray-400 fill-current" d="M3 6l9 4v12l-9-4V6zm14-3v2c0 1.1-2.24 2-5 2s-5-.9-5-2V3c0 1.1 2.24 2 5 2s5-.9 5-2z"></path>
            <polygon className="text-gray-700 fill-current" points="21 6 12 10 12 22 21 18"></polygon>
          </svg>
        </Link>
        <span data-tooltip="Home"></span>
      </li>
      <li className="flex-auto text-gray-700 text-center px-4 py-2 m-2 flex justify-center">
        <Link to="/about" data-tooltip="About">
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-haspopup="true" role="button" tabIndex="0">
            <path className="text-gray-400 fill-current" d="M12 21a2 2 0 0 1-1.41-.59l-.83-.82A2 2 0 0 0 8.34 19H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a5 5 0 0 1 4 2v16z"></path>
            <path className="text-gray-700 fill-current" d="M12 21V5a5 5 0 0 1 4-2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-4.34a2 2 0 0 0-1.42.59l-.83.82A2 2 0 0 1 12 21z"></path>
          </svg>
        </Link>
        <span data-tooltip="About"></span>
      </li>
    </ul>
    <p className="hidden lg:block">Copyright &copy; 2019 Billimarie Lubiano Robinson.</p>
  </footer>
);

export default Footer;
