import React from 'react';

const Contact = () => (
  <div className="py-12 lg:px-8 mx-auto w-4/5 lg:w-2/3 leading-loose text-justify">

    <div className="my-8 text-gray-700 text-2xl lg:text-1xl">
      <ul className="my-8">
        <li className="mb-3">Email: <span className="underline text-gray-600 hover:text-grey-900">work@billimarie.com</span></li>
        <li className="mb-3">Phone: <span className="underline text-gray-600 hover:text-grey-900">(818) 308-5440</span></li>
      </ul>
    </div>

  </div>
);

export default Contact;
