import React from 'react';

import {
  Link
} from 'react-router-dom';

const Evensongs = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./../img/billimarie-lubiano-robinson_evensongs_lyric-essay_2017.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Evensongs</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">July 2017 &bull; Eastern Iowa Review</h3>
        <h4 className="text-gray-300 text-2xl italic">A non-fiction lyric essay written in Sweden, reflecting on Philly, &amp; edited in Los Angeles</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">O</span>riginally published in the Eastern</span> Iowa Review, "Evensongs" is a non-fiction lyric essay. It parallels the alienation &amp; isolation of living/working in Philadelphia with the solace &amp; solitude of living alone, without electricity, running water, or neighbors, in the remote woods of Sweden.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It is good luck to collect rain water.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The smoke billows down. The rain is light in the woods.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">There is mist one could mistake for smoke billowing down.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It brings good luck, catching rainfall.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">There are reasons to stay in the forest and reasons to leave.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I am losing touch with my anxieties and I miss how much they comforted me, like I was altering myself to exist in the wrong place but at least it was an effort. Now there is just calm which means there is no communication between the senses. Just a being that is perceiving of the being.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Calm feels wrong.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">If there is a goodbye that means I plan on coming back.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Without one, there is only an extended absence I intend to keep between us for as long as possible without either of us noticing.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Dirt is a smell I can believe in.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Each piece of wood I split is another piece to keep me warm. I feel sad when I put it into the fire, like we are losing each other in ways we will never regain. But then I see smoke billowing down on days when the rainfall is light and it must dissipate somewhere. Back into the trees, even.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Rooted in the soil.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The tracks in the brown dirt are deeper than footsteps in white snow. One leaves an impression like a ghost. The other presses into itself, a becoming. Something separate is pitted against something merged is what I did here as the smoke billows down, the rain still light in the woods. It doesn't rain every day, but when it does, I smell the old soil and know I can give each piece of wood a name, I can taste&mdash;</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">&mdash;in this reality, I forget to breathe.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I forget what breathing is and that it is a thing I must do to survive.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The train takes me to work. I type and I type and I type.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">There is a rock on my desk to remind me. I take my lunch breaks outside.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The purpose has been abandoned. The purpose has been forgotten in pursual of the purpose.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">A forced remembering.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I wear my best clothes to work. At home, I drift in clothes that are too big for me, despite being made for kids.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">One day I watch the train. One day I watch it take to work without me.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I wonder who will take my place. What they will do with the rock. Will they know its purpose by sight alone. Will they keep it as their own reminder of a life I have no access to.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">When I finish reading, I look up. I am still in the bookstore, which feels strange to me.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I take note of my frenzied heart. The book still open in my hands.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I did not buy the book because I felt that would jinx it, somehow. I did buy the book much later but not from the same bookstore. I bought it because it was the right time for me to own it.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I could no longer rely on remembering.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Leaving behind the bookstore, I ran through the city, I caught the next train, it took over an hour but I got there and I called.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I wanted to tell you about the book. Not the particulars, because I wanted you to read it on your own, someday, when we had forgotten each other. I wanted to show you how the book made me feel, my reaction to its words. I thought of you then in the bookstore and knew that I had to go to you.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">When we met, all the words were wrong.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I did not get to tell you about the book, and I never would.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I move through the mist of the woods and know this is a place I can keep and be kept in.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I think I like this earth more and more with every passing day. I don't know how I will say goodbye. What it will be like when I leave. So I say it every day.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Ash in the snow. Everyone goes.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">A nice home at the end of a long road.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">If the kindling goes right, the smoke will blanket the evening star.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I breathe in and wonder how my friends are doing. If they even are my friends. If I even have friends if I've been gone for too long and never stay.</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center">~</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The rain comes. I go outside and shower. I hear a plane I cannot see, listen to a road with no cars.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I soap my skin. I use my hands as cups. I get water from the well and use a pail with light blue rope.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">There's stars above the mist. Cold setting in.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Smoke billowing down, the rain still light in the woods.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Every puddle a stained glass window. Every glance catching me with a smile.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Even light catches luck.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It catches hold of me.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I left it in the water for there was no more chasing after it.</p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Only feeling it all at once.</p>

    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full" style={{height:1350 + "px"}}>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_selfies-taken-from-a-healthy-distance-nova-scotia_2016.jpg" alt="Evensongs - The Forest - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_the-forest-01_2016.jpg" alt="Evensongs - The Forest - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_the-forest-02_2016.jpg" alt="Evensongs - The Forest - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_lyric-essay_2017.jpg" alt="Evensongs - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_lyric-essay_2017_02.jpg" alt="Evensongs - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_lyric-essay_2017_03.jpg" alt="Evensongs - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_evensongs_lyric-essay_2017_04.jpg" alt="Evensongs - Billimarie Lubiano Robinson" />
      </div>



    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12 art">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/thumbs/billimarie-lubiano-robinson_epiphonia_february-2016.gif" alt="Epiphonia - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Epiphonia</div>
              <p className="italic font-bold text-gray-500 mb-2">?, Los Angeles CA</p>
              <p className="text-gray-700 text-base mb-2">
                A burecratic love letter from the Governing Bodies of the Universe addressed specifically to You. Limited Edition, handmade miniature book.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12 art">
          <div className="bg-white rounded shadow-lg">
            <Link to="/imagination-is-the-new-work" className="work-card-link"></Link>
            <img src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_rittenhouse-square_philly-2014.jpg" alt="Imagination Is The New Work - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Imagination Is The New Work</div>
              <p className="italic font-bold text-gray-500 mb-2">Winter 2014</p>
              <p className="text-gray-700 text-base mb-2">
                An essay dissecting the nature of capitalism &amp; art while simultaneously forcing the self to burn out on said art.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12 art">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/thumbs/billimarie-lubiano-robinson_you-are_in-which-our-heroine_film_2014.png" alt="You Are* - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">You Are*</div>
              <p className="italic font-bold text-gray-500 mb-2">Autumn 2014</p>
              <p className="text-gray-700 text-base mb-2">
                An experimental documentary in which our heroine attempts to subvert reality by burning out on art.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default Evensongs;
