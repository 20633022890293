import React from 'react';
import {
  Link
} from 'react-router-dom';

import HomeFilter from './HomeFilter.js';

const Home = () => (

<div className="w-full mx-auto flex flex-col md:inline-block md:px-4 lg:px-2 lg:py-12">

  <HomeFilter />

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/hopeworks-web" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_hopeworks-web-presentation_summer-2019.jpg" alt="Hopeworks Web - Camden, NJ - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Hopeworks Web</div>
        <p className="italic font-bold text-gray-500 mb-2">July 2018 - October 2019, Camden NJ</p>
        <p className="text-gray-700 text-base mb-2">
          Created a team culture of collaboration through best practices in design &amp; code, which enhanced product pricing within the year by 200%.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/free-poetry/tesla-spacex" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_free-poetry_tesla-space-x_valentines-day-2018.jpg" alt="Typewriter Poetry - Tesla, SpaceX Valentine's Day - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Free Poetry: Tesla, SpaceX</div>
        <p className="italic font-bold text-gray-500 mb-2">February 2018, Los Angeles</p>
        <p className="text-gray-700 text-base mb-2">
          Writing <span className="italic">Typewriter Poetry</span> for Tesla &amp; SpaceX employees on Valentine's Day.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coded-by-kids" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_coded-by-kids_ctrl-shift-mentor_2018.jpg" alt="Coded by Kids - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Coded by Kids</div>
        <p className="italic font-bold text-gray-500 mb-2">March 2017 - June 2019, Philadelphia PA</p>
        <p className="text-gray-700 text-base mb-2">
          Taught high school and elementary students how to design &amp; code from scratch in classrooms throughout Philadelphia and Camden.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/imagination-is-the-new-work" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_rittenhouse-square_philly-2014.jpg" alt="Imagination Is The New Work - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Imagination Is The New Work</div>
        <p className="italic font-bold text-gray-500 mb-2">Winter 2014</p>
        <p className="text-gray-700 text-base mb-2">
          An essay dissecting the nature of capitalism &amp; art while simultaneously forcing the self to burn out on said art.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_clasp_2016.jpg" alt="CLASP - Community Legal & Advocacy Services Project - Billimarie Lubiano Robinson - Northridge Review" />
      <div className="p-4">
        <div className="font-bold text-xl">CLASP</div>
        <p className="italic font-bold text-gray-500 mb-2">2016 - 2017, Princeton NJ</p>
        <p className="text-gray-700 text-base mb-2">
          Co-Founder & Director of Technology for the Community Legal & Advocacy Services Project, a nonprofit project advocating for prisoners' rights across prisons in New Jersey.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_stem-diversity-podcast_message-agency_july-2017.jpg" alt="Bringing Nonprofits to the Digital World - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Bringing Nonprofits to the Digital World</div>
        <p className="italic font-bold text-gray-500 mb-2">July 2017, STEM Diversity Podcast</p>
        <p className="text-gray-700 text-base mb-2">
          An interview on the Diversity in STEM podcast about helping nonprofits through tech.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/girl-develop-it_baylors-womens-prison_coding-class_2018.jpg" alt="Girl Develop It - Baylor Women's Correctional Insitution" />
      <div className="p-4">
        <div className="font-bold text-xl">Girl Develop It</div>
        <p className="italic font-bold text-gray-500 mb-2">Summer 2019, Baylor Women's Correctional Institution</p>
        <p className="text-gray-700 text-base mb-2">
          Taught women currently incarcerated how to code HTML &amp; CSS from scratch, as well as create CSS customizations in WordPress.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_web-app-design-dev-tech_clocktwine_october-2019.png" alt="Clocktwine" />
      <div className="p-4">
        <div className="font-bold text-xl">Clocktwine</div>
        <p className="italic font-bold text-gray-500 mb-2">October 2019, Los Angeles, CA</p>
        <p className="text-gray-700 text-base mb-2">
          Premium website &amp; web app design, development, hosting, &amp; maintenance services based on a monthly subscription.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_girl-develop-it_ladyhacks_2017_01.jpg" alt="Girl Develop It - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">LadyHacks</div>
        <p className="italic font-bold text-gray-500 mb-2">2017, Philadelphia PA</p>
        <p className="text-gray-700 text-base mb-2">
          Pitched &amp; led a hackathon group project focused on prototyping the U.S. Prosecutor Database web app.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_how-to-lead-a-hackathon_ladyhacks_girl-develop-it_2017.jpeg" alt="How To Lead A Hackathon - Ladyhacks - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">How To Lead A Hackathon</div>
        <p className="italic font-bold text-gray-500 mb-2">Spring 2017, Ladyhacks</p>
        <p className="text-gray-700 text-base mb-2">
          Reflecting on how to lead a hackathon.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/literary-creatures" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg" alt="Literary Creatures - Philly Fringe Fest 2018" />
      <div className="p-4">
        <div className="font-bold text-xl">Literary Creatures</div>
        <p className="italic font-bold text-gray-500 mb-2">September 2018, Philly Fringe Fest</p>
        <p className="text-gray-700 text-base mb-2">
          An improvisational, sensory performance piece featuring one <span className="italic">A. Ti Nyalien</span>.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_mangled-crystalis-heart_painting_april-2017.jpg" alt="Mangled Crystalis - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">mangled.crystalis();</div>
        <p className="italic font-bold text-gray-500 mb-2">April 2017, Painting Series</p>
        <p className="text-gray-700 text-base mb-2">
          Titles are based on the anatomy of a JavaScript function, &amp; take after a private metaphor (<em>mangled.crystalis</em>). The parameter is the changing element.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/literary-carnival" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Literary Carnival - Typewriter Poetry - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Literary Carnival</div>
        <p className="italic font-bold text-gray-500 mb-2">August 2013, the Newer York</p>
        <p className="text-gray-700 text-base mb-2">
          Tarot, drag, &amp; <span className="italic">Typewriter Poetry</span> before the show.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_private-drafts_typewriter-poetry_2019.jpg" alt="Private Drafts - Typewriter Poetry - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Private Drafts</div>
        <p className="italic font-bold text-gray-500 mb-2">January 2019 - March 2019, Cedar Park</p>
        <p className="text-gray-700 text-base mb-2">
          An attempt to type one page per day at Cedar Park for a whole year. <em>Private Drafts</em> ended up lasting for a season (3 months).
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_corrugated-hearts_film_2010.jpg" alt="Corrugated Hearts - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Corrugated Hearts</div>
        <p className="italic font-bold text-gray-500 mb-2">August 2010, Princeton Film Festival</p>
        <p className="text-gray-700 text-base mb-2">
          In a world of 2.0 boxheads, Jake must choose between an upgrade or love.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/evensongs" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_evensongs_lyric-essay_2017.jpg" alt="Evensongs - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Evensongs</div>
        <p className="italic font-bold text-gray-500 mb-2">Spring 2017, Eastern Iowa Review</p>
        <p className="text-gray-700 text-base mb-2">
          A published lyric essay essay written in Sweden, referencing Philly, &amp; edited in Los Angeles.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/free-poetry/princeton" className="work-card-link"></Link>
      <img style={{objectPosition: "top"}} src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Beyond Words - Princeton Public Library's Annual Gala - Typewriter Poetry - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Free Poetry: Beyond Words Gala</div>
        <p className="italic font-bold text-gray-500 mb-2">December 2014, Princeton, NJ</p>
        <p className="text-gray-700 text-base mb-2">
          Performing <span className="italic">Typewriter Poetry</span> during Princeton Public Library's annual fundraiser.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_tilt_painting_november-2017.jpg" alt="Tilt - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Tilt</div>
        <p className="italic font-bold text-gray-500 mb-2">November 2017</p>
        <p className="text-gray-700 text-base mb-2">
          Large acrylic painting.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_us-prosecutor-database_open-source_2019.gif" alt="US Prosecutor Database - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">US Prosecutor Database</div>
        <p className="italic font-bold text-gray-500 mb-2">Ongoing, Open-Source</p>
        <p className="text-gray-700 text-base mb-2">
          An open-source civic data web app. Created with Heroku, MongoDB, &amp; Meteor.js
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_valentine-ii_painting_february-2017.jpg" alt="Valentine - Billimarie Lubiano Robinson - Paintings" />
      <div className="p-4">
        <div className="font-bold text-xl">Valentine</div>
        <p className="italic font-bold text-gray-500 mb-2">February 2017</p>
        <p className="text-gray-700 text-base mb-2">
          A painting series (I-VIII).
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Free Poetry (2015)</div>
        <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
        <p className="text-gray-700 text-base mb-2">
          Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_in-the-blink_northridge-review_2010.png" alt="In The Blink - Billimarie Lubiano Robinson - Northridge Review" />
      <div className="p-4">
        <div className="font-bold text-xl">In The Blink</div>
        <p className="italic font-bold text-gray-500 mb-2">Spring 2010, Northridge Review</p>
        <p className="text-gray-700 text-base mb-2">
          A published poem about film editing, death, and Frank Herbert's <em>Dune</em> series.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_you-are_in-which-our-heroine_film_2014.png" alt="You Are* - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">You Are*</div>
        <p className="italic font-bold text-gray-500 mb-2">Autumn 2014</p>
        <p className="text-gray-700 text-base mb-2">
          An experimental documentary in which our heroine attempts to subvert reality by burning out on art.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_pipe-dream-tactics_april-2015.gif" alt="Thinking About Pipe Dreams - Pipe Dream Tactics - Billimarie Lubiano Robinson - Northridge Review" />
      <div className="p-4">
        <div className="font-bold text-xl">Thinking About Pipe Dreams</div>
        <p className="italic font-bold text-gray-500 mb-2">April 2015, Pipe Dream Tactics</p>
        <p className="text-gray-700 text-base mb-2">
          An online penpal community. Based upon a philosophical essay playing with Ricoeur's ideas of future anticipated actions &amp; how they relate to pipe dreams.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_taotptihk_to-all-of-the-places-that-i-have-known_handmade-miniature-book_2018.jpg" alt="TAOTPTIHK (To All Of The Places That I Have Known) - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">To All Of The Places That I Have Known</div>
        <p className="italic font-bold text-gray-500 mb-2">April 2018</p>
        <p className="text-gray-700 text-base mb-2">
          A handmade miniature book in which our narrator waxes poetic on the decision to adventure by wandering recklessly.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_typewriter-poetry_lady-palabra_painting_2015.jpg" alt="Lady Palabra - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Lady Palabra</div>
        <p className="italic font-bold text-gray-500 mb-2">April 2015</p>
        <p className="text-gray-700 text-base mb-2">
          Acrylic painting mixed with <span className="italic">Typewriter Poetry</span>.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_sugar-water_film_2006.png" alt="Sugar Water - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Sugar Water</div>
        <p className="italic font-bold text-gray-500 mb-2">June 2006</p>
        <p className="text-gray-700 text-base mb-2">
          An experimental documentary about multiracial identity, loosely based on non-fictional events.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 tech">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_philly-tech-week_activism-tech_2018.jpg" alt="Philly Tech Week 2018 - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Philly Tech Week 2018</div>
        <p className="italic font-bold text-gray-500 mb-2">May 2018, Philadelphia PA</p>
        <p className="text-gray-700 text-base mb-2">
          Hosted a lecture &amp; open-floor discussion about the parallels between activist &amp; open-source communities.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_the-eggberts_episode-six-screenshot.png" alt="The Eggberts - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">The Eggberts</div>
        <p className="italic font-bold text-gray-500 mb-2">Summer 2010 - 2014, Los Angeles CA</p>
        <p className="text-gray-700 text-base mb-2">
          A stop-motion animated YouTube series about two eggs just trying to make it in the big office.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card px-4 py-12 art">
    <div className="bg-white rounded shadow-lg">
      <Link to="/coming-soon" className="work-card-link"></Link>
      <img src="./img/thumbs/billimarie-lubiano-robinson_epiphonia_february-2016.gif" alt="Epiphonia - Billimarie Lubiano Robinson" />
      <div className="p-4">
        <div className="font-bold text-xl">Epiphonia</div>
        <p className="italic font-bold text-gray-500 mb-2">?, Los Angeles CA</p>
        <p className="text-gray-700 text-base mb-2">
          A burecratic love letter from the Governing Bodies of the Universe addressed specifically to You. Limited Edition, handmade miniature book.
        </p>
      </div>
    </div>
  </div>

  <div className="work-card text-card pt-2 pb-12">
    <p className="text-6xl"><span role="img" aria-label="Black & Brown Goddess">🙇🏾‍♀️</span></p>
    <p>Copyright &copy; 2019 Billimarie Lubiano Robinson.</p>
  </div>

</div>

);

export default Home;
