import React from 'react';

import {
  Link
} from 'react-router-dom';

const LiteraryCarnival = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Literary Carnival</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">? 2013 &bull; Los Angeles, CA</h3>
        <h4 className="text-gray-300 text-2xl italic">Performing Typewriter Poetry at the Newer York show</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">W</span>hile wandering in Oregon, the</span> Newer York contacted me about appearing at their annual Literary Carnival. I got back home to Los Angeles just in time last Friday for the event.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The carnival itself is a theatrical book release for an experimental literary journal. The show was full of performance, readings, social experiments, short films, and curiously quirky delights for the senses.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I did Typewriter Poetry during the pre-show. Set out a few tarot cards from The Goddess Tarot Deck (major arcana, if you were curious), rearranged candles, plopped down my typewriter and wrote free poems for the guests. Incorporating tarot card interaction made for an interesting poetry experience.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">“Some guests interacted with tarot cards next to a planter box garden of orchids and fledgling pineapples while poet Billimarie called upon the Muses and typed out impromptu verse on her baby pink typewriter.” —Henri Maddocks</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It was an amazing night for literature. Cheers to the Los Angeles summer night, whimsical ambiance, the Newer York (especially Ian, Michelle, Josh), and all the literary strings that made this sensation possible.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Thanks to Luke Gattuso who took these photos and was kind enough to post them online. You can view the rest of the Literary Carnival set here.</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full">

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Typewriter Poetry - Literary Carnival" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013_01.jpg" alt="Close Up of Billimarie Lubiano Robinson - Literary Carnival" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013_02.jpg" alt="Typewriter Poetry - Literary Carnival" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013_03.jpg" alt="Typewriter Poetry - Literary Carnival" />
      </div>

    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-creatures" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg" alt="Literary Creatures - Philly Fringe Fest 2018" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Creatures</div>
              <p className="italic font-bold text-gray-500 mb-2">September 2018, Philly Fringe Fest</p>
              <p className="text-gray-700 text-base mb-2">
                An improvisational, sensory performance piece featuring one <span className="italic">A. Ti Nyalien</span>.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2015)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
              <p className="text-gray-700 text-base mb-2">
                Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/princeton" className="work-card-link"></Link>
            <img style={{objectPosition: "top"}} src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Beyond Words - Princeton Public Library's Annual Gala - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry: Beyond Words Gala</div>
              <p className="italic font-bold text-gray-500 mb-2">December 2014, Princeton, NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Performing <span className="italic">Typewriter Poetry</span> during Princeton Public Library's annual fundraiser.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default LiteraryCarnival;
