import React from 'react';

import {
  Link
} from 'react-router-dom';

const CodedByKids = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson_coded-by-kids_ctrl-shift-mentor_2018.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Coded By Kids</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">March 2017 - March 2019 &bull; Philadelphia, PA & Camden, NJ</h3>
        <h4 className="text-gray-300 text-2xl italic">Teaching elementary &amp; high school kids HTML, CSS, & Git/GitHub</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">A</span> chance encounter with Maggie</span> Deptola, the COO of Coded By Kids, brought me into teaching. After we met during an interview, she pulled me aside and complimented my presentation skills. "Have you ever thought about teaching?" she asked. I told her I hadn't.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">A few weeks later, I found myself in front of my first classroom: ninth graders at Bartram HS in West Philly, a couple minutes away from my apartment. I shadowed Sylvester Mobley &amp; Michaela Ochnich, the CEO &amp; CTO, for a few classes before leading the classroom on my own. That first semester, I learned a lot by listening to students talk about their classes while we would work on their personal coding projects. It's so strange that we live in a world where teachers can become jaded enough not to even look a student in a eye. I made it a personal mission to attempt to connect with every student I came across.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">From 2017 to 2018, I taught coding classes during and after school hours at several schools throughout Philadelphia &amp; Camden: Bartram HS, Kensington Health & Sciences Academy (KHSA), South Philly High, KIPP Elementary, &amp; the Workshop School. We dove into the fundamentals of HTML &amp; CSS, and practiced commiting our code via Git to GitHub through Terminal every class. Students had the chance to work on projects of their choosing&mdash;most picked video games &amp; celebrities, some chose business ideas.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Often, my classrooms were specifically hand-picked to be the place where executive sponsors from large businesses like Capital One &amp; Comcast could witness first-hand the value of our programming. It was an excellent experience for the students, as these were two worlds which rarely had the chance to interact.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Though each class was demanding in its own right&mdash;I often chose to go around and help each student individually after a group lesson, which means lots of conversations &amp; questions to keep everyone engaged in their project&mdash;it was an extremely rewarding experience at the end of every session. I'm thankful to have crossed paths with so many talented individuals during their elementary &amp; high school journeys, &amp; look forward to reading about their projects, one day.</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full" style={{height:1650 + "px"}}>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_coding-club-gallery_2016.PNG" alt="Coding Club - Coded by Kids - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_bartram-resource-01_2017.gif" alt="Coded by Kids - Bartram HS - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/coded-by-kids_khsa_student-brainstorm-01_billimarie-lubiano-robinson.JPG" alt="Coded by Kids - Student Brainstorm (KHSA) - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/coded-by-kids_khsa_student-brainstorm-02_billimarie-lubiano-robinson.JPG" alt="Coded by Kids - Student Brainstorm (KHSA) - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_khsa_coded-by-kids_2018.gif" alt="Coded by Kids - Kensington Health & Sciences Academy (KHSA) - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_ctrl-shift-mentor_2018.jpg" alt="Coded by Kids" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids-fundraiser-02_2017.jpg" alt="Coded by Kids - Fundraiser" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_bartram-resource-02_2017.gif" alt="Coded by Kids - Bartram HS - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_fundraiser_2017.PNG" alt="Coded by Kids" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_ctrl-shift_2018.PNG" alt="Coded by Kids" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_coded-by-kids_bartram-hs_shaconna-r-website_2017.PNG" alt="Coded by Kids - Student Website - Bartram HS - Billimarie Lubiano Robinson" />
      </div>

    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_stem-diversity-podcast_message-agency_july-2017.jpg" alt="Bringing Nonprofits to the Digital World - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Bringing Nonprofits to the Digital World</div>
              <p className="italic font-bold text-gray-500 mb-2">July 2017, STEM Diversity Podcast</p>
              <p className="text-gray-700 text-base mb-2">
                An interview on the Diversity in STEM podcast about helping nonprofits through tech.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_clasp_2016.jpg" alt="CLASP - Community Legal & Advocacy Services Project - Billimarie Lubiano Robinson - Northridge Review" />
            <div className="p-4">
              <div className="font-bold text-xl">CLASP</div>
              <p className="italic font-bold text-gray-500 mb-2">2016 - 2017, Princeton NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Co-Founder & Director of Technology for the Community Legal & Advocacy Services Project, a nonprofit project advocating for prisoners' rights across prisons in New Jersey.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/hopeworks-web" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_hopeworks-web_spring-2019.jpg" alt="Hopeworks Web - Camden, NJ - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Hopeworks Web</div>
              <p className="italic font-bold text-gray-500 mb-2">July 2018 - October 2019, Camden NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Created a team culture of collaboration through best practices in design &amp; code, which enhanced product pricing within the year by 300%.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default CodedByKids;
