import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Analytics from 'react-router-ga';

import ScrollToTop from 'react-router-scroll-top';

import './css/tailwind.css';

import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Notes from './components/Notes.js';
import Contact from './components/Contact.js';
import Website from './components/Website.js';

import LiteraryCreatures from './components/projects/LiteraryCreatures.js';
import LiteraryCarnival from './components/projects/LiteraryCarnival.js';
import TeslaSpaceX from './components/projects/TeslaSpaceX.js';
import PrincetonBeyondWords from './components/projects/PrincetonBeyondWords.js';
import HopeworksWeb from './components/projects/HopeworksWeb.js';
import CodedByKids from './components/projects/CodedByKids.js';
import Evensongs from './components/projects/Evensongs.js';
import ImaginationIsTheNewWork from './components/projects/ImaginationIsTheNewWork.js';
import ComingSoon from './components/projects/ComingSoon.js';

const App = () => (
  <Router>
    <Analytics id="UA-16805057-2" trackPathnameOnly>
      <ScrollToTop>

        <Header />

          <Switch>

            <Route exact path="/">
              <div className="mobile-container my-12">
                <Home />
              </div>
            </Route>

              <Route exact path="/evensongs">
                <div className="my-12 lg:py-10">
                  <Evensongs />
                </div>
              </Route>
              <Route exact path="/imagination-is-the-new-work">
                <div className="my-12 lg:py-10">
                  <ImaginationIsTheNewWork />
                </div>
              </Route>
              <Route exact path="/literary-creatures">
                <div className="my-12 lg:py-10">
                  <LiteraryCreatures />
                </div>
              </Route>
              <Route exact path="/literary-carnival">
                <div className="my-12 lg:py-10">
                  <LiteraryCarnival />
                </div>
              </Route>
              <Route exact path="/free-poetry/tesla-spacex">
                <div className="my-12 lg:py-10">
                  <TeslaSpaceX />
                </div>
              </Route>
              <Route exact path="/free-poetry/princeton">
                <div className="my-12 lg:py-10">
                  <PrincetonBeyondWords />
                </div>
              </Route>
              <Route exact path="/hopeworks-web">
                <div className="my-12 lg:py-10">
                  <HopeworksWeb />
                </div>
              </Route>
              <Route exact path="/coded-by-kids">
                <div className="my-12 lg:py-10">
                  <CodedByKids />
                </div>
              </Route>
              <Route exact path="/coming-soon">
                <div className="my-12 lg:py-10">
                  <ComingSoon />
                </div>
              </Route>

            <Route exact path="/about">
              <div class="my-12 lg:py-10">
                <About />
              </div>
            </Route>

            <Route exact path="/notes">
              <div class="my-12 lg:py-10">
                <Notes />
              </div>
            </Route>

            <Route exact path="/contact">
              <div class="my-12 lg:py-10">
                <Contact />
              </div>
            </Route>

            <Route exact path="/website">
              <div class="my-12 lg:py-10">
                <Website />
              </div>
            </Route>

          </Switch>

        <Footer />

      </ScrollToTop>
    </Analytics>
  </Router>
);

export default App;
