import React from 'react';

import {
  Link
} from 'react-router-dom';

const ImaginationIsTheNewWork = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson_typewriter-poetry_rittenhouse-square_philly-2014.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Imagination Is The New Work</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">Winter 2014 &bull; Philadelphia, PA</h3>
        <h4 className="text-gray-300 text-2xl italic">dissecting the nature of capitalism &amp; art while simultaneously forcing the self to burn out on said art</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify hyphens-soft text-2xl lg:text-1xl text-gray-700">

      <p className="my-8 mx-auto text-gray-500 italic w-2/3">It’s not that artists shouldn’t be paid for their labor, but we ought to refuse the assignation of value and worth purely based on salability or the validation of institutions. Systems will always seek to swallow us. We must resist the efficiency of its gears with the softness of our humanity. Unprofessionalism is asserting our right to be human against this machine.<br />&mdash; from <a href="http://momus.ca/how-to-be-an-unprofessional-artist/" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">“How To Be an Unprofessional Artist”</span></a> by Andrew Berardini</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8"><span className="uppercase font-bold"><span className="dropcap">W</span>hat kind of year can</span> leave you hallow?</p>
      <p className="my-8">(The kind without your own bed.)</p>
      <p className="pt-6 mt-12 mb-8 text-gray-500 italic w-2/3 text-4xl leading-relaxed">This was the year music<br />was revealed to me. I don’t<br /><br />know if it’s possible<br />to live off Free Poetry.</p>
      <p className="my-8">You accept what this path leads to, what it consists of. You have forgotten what ennui feels like.</p>
      <p className="my-8">But there is also your mother asking in a reverent whisper if you are homeless. With little to no steady employment nor living space, there’s room for obsession in your veins: <strong>Reality, Reality, Life, Art, Capitalism, Reality, Life</strong>. There’s room to fall in love with people places times and moments. Over and over again, every day—this is not exaggeration.</p>
      <p className="my-8">Your bones shiver smiles.</p>
      <p className="my-8">It comes easy, connecting with strangers. It is hard retaining friends.</p>
      <p className="my-8">You receive the good will you give away.</p>
      <p className="my-8">There is sleeping in train stations and people maliciously kicking you awake.</p>
      <p className="my-8">There is restructuring narrative. There is sleeping in train stations and stumbling upon this year’s newest technology in Experimental Alarm Clocks.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">“Love what you do and you will never work a day in your entire life.”<br /><br />Capitalist scum drivel aimed at convincing you work is one-dimensional suffering you can somehow escape from.</p>
      <p className="my-8">Don’t swallow. Chew. You <em>know</em> work. You know work because you destroy, you create. Work stripped bare is called Effort. We believe there are distinctions between Work and Play. There aren’t. Work is a force and to play you need motion. It takes effort to be alive. Don’t believe it? Then stop. Forsake your limbs, your breath, your imagination, your frantic beating heart.</p>
      <p className="my-8">Newsflash: the aspidistra isn’t flying. It’s falling. IMAGINATION IS THE NEW WORK. (Or OLD WORK. I’m still not sold on the concept of time.)</p>
      <p className="my-8">You are not ancient tea leaves; you are ancient hands harvesting fresh herbs. You are the act which extracts flavor. The swivel of water within ceramic pots once made from dirt.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">“No, really. What do you do?”</p>
      <p className="my-8">Well. I watch <em>Kiki’s Delivery Service</em> when I’m home alone and cry.</p>
      <p className="my-8">Here are the specifics:</p>
      <ul className="list-disc ml-6">
        <li>I am <strong>for</strong> throwing one’s entire self into Life/Art</li>
        <li>I am <strong>against</strong> the capitalization of Life/Art</li>
        <li>I am <strong>unsure</strong> how to navigate the spectrum of finance in regards to Life/Art</li>
        <li>I might have, <strong>unknowingly</strong>, structured Life/Art into a commodity fetish</li>
      </ul>
      <p className="my-8">I am aware of the dangers in romanticizing a certain lifestyle (let’s call it “wandering”) and demonizing another (“nine to five”). I have a strong desire to be productive, so that my productivity can help provide for my family, my friends, my self. I recognize that my belief of what it means to be productive may be drastically different than the definition reality (“real life”) holds as the standard.</p>
      <p className="my-8 italic">Last Wednesday, I was given $1.50 in donations for typing over 10 poems in a straight 5 hour period.</p>
      <p className="my-8">Several people have made helpful suggestions:</p>
      <p className="my-8">Tweak or adapt the Typewriter Poetry manifesto and spirit (on <a href="https://web.archive.org/web/20120824033335/http://www.typewriterpoetry.com/about" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">the original About page</span></a>: <strong>“Poems are free because I am of the radical <em>[to the root of things, to the origin]</em> belief that many things in life can and should be without monetary value”</strong>).</p>
      <p className="my-8">Drop the word “free.”</p>
      <p className="my-8">Charge a base price per poem.</p>
      <p className="my-8">Include a suggested donation amount.</p>
      <p className="my-8">Apply for sponsorship.</p>
      <p className="my-8">Stop typing free poems for people and publish a series of chapbooks which you can sell.</p>
      <p className="my-8">Apply for a grant.</p>
      <p className="my-8">Focus on getting published.</p>
      <p className="my-8">Go back to computer work. Graphic Design. Freelance.</p>
      <p className="my-8">Market yourself.</p>
      <p className="my-8">Compromise. Just Grow The Fuck Up.</p>
      <p className="my-8">You have an obligation to put your <em>own</em> obligations to yourself aside. Stop being continually broke. Find a better day job. Drop the ideations. The idealizations. Settling <em>is</em> maturity. Abandon imagination—it is a course that will lead you to Nowhere.</p>
      <p className="my-8">One night I had dreams. Another night I forgot them. I’ve made a pact with my body which my mind has no rights to: I shoot myself in the fucking face once we are no longer capable of dreaming.</p>
      <p className="my-8">Here is “The Process.” Here are dreams, here is art, here is expression. Throw that body into the thralls of inspiration. Yes, it has led you down an unproductive path. Yes, it will abandon you to a space without answers. But tonight you are shaman and you shake like the stars. Just because they haven’t figured out a way to capitalize on this resource doesn’t mean its useless. Your mind is my mind’s final frontier.</p>
      <p className="my-8">My last sleep was full of birth. Bodily (mis)configuration. Mundane beauty, visceral love. Life.</p>
      <p className="my-8">Who says Nowhere is a bad place to be?</p>
      <p className="my-8">I repeat: IMAGINATION IS THE NEW WORK.</p>
      <p className="my-8">Does your portfolio need more diversity?</p>
      <p className="my-8">FREE POETRY is not a celebration of bullshit on paper. <em>(Are you sure? Is this avant-garde attempt really just a self-made assembly line of mass produced poetics?)</em> Certainly, it results in a product <em>(1 Free Poem)</em> but it is not that product in and of itself. You push out poems because you’ve always pushed out poems. What else are you going to do? Sell them?</p>
      <p className="my-8">At best, these poems are travel-size bullshit containing small golden nugget flecks of potential.</p>
      <p className="my-8"><em>(I may be my harshest critic, but don’t tell me I’m not fully committed to the cause.)</em></p>
      <p className="my-8">What are these bits known as potential? The potential to Actualize, to establish itself as an individual authenticity. To be <em>A Poet’s Poem</em>! Because I never keep copies of poems, I lose (I mean, <em>I lovingly release and give back to the universe</em>) these golden utterances. There is no chance for the sculpting, reshaping, chipping away—no chance to continue The Process and discover, at work’s end, a Finished Poem.</p>
      <p className="my-8">And at worst? These poems are herd. They are lost in the sea of mundane unoriginal voice, reciting the same echoes of truth over and over again until even the replicas of the replicas become fading type on pretty paper. The recipe: insert the word “life,” “eyes,” “shadows,” “light,” or “root.” Instill gratitude. Dreams. Recall specific occurrences which are loosely correlated to the central optimistic metaphor, then conclude with a vague speculation or sentiment about how awesome it is to experience and perceive whatever the hell <em>LIFE!</em> is.</p>
      <p className="my-8">Free Poetry. Free Poetry, indeed. Free Poetry from the confines of expectation (see above). Free Poetry from the language of pretension, from the desire not to use too many of’s or mixed metaphors or run-on imagery. Free Poetry from marketing, from publishing, from events on Facebook. From discourse. From the poet’s intent and the reader’s interpretation.</p>
      <p className="my-8">The final prayer: Free Poetry so that it can be <em>poesis</em>. A verb. To make, to create.</p>
      <p className="my-8">Who says you don’t understand poetry? How <em>dare</em> you. Going so far as to pretend like you don’t work—IMAGINATION IS THE NEW WORK.</p>
      <p className="my-8">Dear inertia, you’re dragging me down.<br />I think it’s time we see other people.</p>
      <p className="my-8 italic">Last Thursday, I was given $9 in donations for typing over 5 poems in a 2 hour period.</p>
      <p className="my-8">This is your call to adventure: to subvert an unwanted reality, one must burn out on Art. You are in The Process of Producing Art at a maniacal pace (I <a href="https://www.youtube.com/watch?feature=player_embedded&v=8hYTzUZmJjs" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">have done that</span></a>, I am <a href="http://kck.st/1yt8JKr" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">still doing that</span></a>.) You are exploring the work needed to marry Life/Art and calling it <strong>“…in which our heroine.”</strong> A living love letter, <strong>“…in which our heroine”</strong> is a public cry and virtual examination of your/our/we/my own sickness* as a testament to—what, life? art? conviction, naiveté, love, poetry, and all the other words that are supposed to mean something, because</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">* “…live you must, and you can either make passive love to your sickness or burn it out and go on to the next conflicting phase.” ― Ralph Ellison, Invisible Man</p>
      <p className="my-8">Here is my sickness. Hey, hi, hello. I have gotten more shit done in the past week than in the past four months. I am done with wavering, with passive availability. We can no longer accept teetering. It is time to burn this phase out.</p>
      <p className="my-8">Here are the technicalities. It is $6.75 for a sandwich at the library cafe. $.50 for washing one load of laundry. 40 minutes to walk to the station. $15.00 for taking three trains out of New Jersey. I need to call my family. I still haven’t responded to months of snail mail. My $50 credit bill was due when I only had $2 in quarters left. And yet—</p>
      <p className="my-8">I have been working. Real work. My kind of work. All forms of work, including re-re-re-re-editing all those unseen poems and stories and plays and screenplays and novels. I have been calling it all bullshit, then tossing everything back into the closet. What’s wrong with bullshit? I am a Taurus, and I believe in organic fertilizer. When I’m sad, I go over my work one by one and laugh. I finish with a feeling that I am less alone than I previously thought. I am connected to The Author as she existed two minutes ago, four months ago, seven years ago.</p>
      <p className="my-8">Life is thick with convoluted myth.</p>
      <p className="my-8">I could go on. I won’t.</p>
      <p className="my-8">I asked to borrow a pen and even promised to return it. The woman behind the counter said if it’s not back by the end of her shift, she’ll get written up.</p>
      <p className="my-8">There is the dislike of ending on a sentimental note. There is the need to express gratitude, but a desire to keep rooted and cease the mush.</p>
      <p className="my-8">But—thank you. Whether I know you, don’t know you, have only met you once…thank you. Whether you’ve donated (thank you), have never donated (…), or offered me something beyond a monetary tip—oh, yes, thank you!</p>
      <p className="my-8">To be thrown into the world with a person, a no-longer stranger. You have brushed against my ego and helped dismantle and rebuild it. I am now a castle built from clouds and sand.</p>
      <p className="my-8">We are equal parts fiction and equal parts truth. If only you could witness the world manifesting in your eyes. You wouldn’t dare start off and end your stories with a “so.”</p>
      <p className="my-8">To hugs and awkwardness and laughs and apathy and tears and cries and handshakes and high fives and waving goodbye—yes, the words of dreams you’ve shared with me collect<br />like dust along my lashes. Pluck<br /><br />fruit. Harvest rains. I worship<br />the sound a stab in the heart<br />makes</p>

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 italic">Originally published on Typewriter Poetry. Republished on Medium. Photography by <a href="https://www.tomreifsnyder.com" _blank="nofollow noopener"><span className="underline text-gray-600 hover:text-grey-900">Tom Reifsnyder</span></a>.</p>
    </div>

    <div className="mb-12 mx-auto">
      <p className="block mx-auto mb-12 pb-6 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-carnival" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Literary Carnival - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Carnival</div>
              <p className="italic font-bold text-gray-500 mb-2">August 2013, the Newer York</p>
              <p className="text-gray-700 text-base mb-2">
                Tarot, drag, &amp; <span className="italic">Typewriter Poetry</span> before the show.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2015)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
              <p className="text-gray-700 text-base mb-2">
                Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12 art">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-creatures" className="work-card-link"></Link>
            <img src="./img/thumbs/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg" alt="Literary Creatures - Philly Fringe Fest 2018" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Creatures</div>
              <p className="italic font-bold text-gray-500 mb-2">September 2018, Philly Fringe Fest</p>
              <p className="text-gray-700 text-base mb-2">
                An improvisational, sensory performance piece featuring one <span className="italic">A. Ti Nyalien</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default ImaginationIsTheNewWork;
