import React from 'react';

const Notes = () => (
  <div className="py-12 lg:px-8 mx-auto w-4/5 lg:w-2/3 leading-loose text-justify">

    <div className="my-8 text-gray-700 text-2xl lg:text-1xl">
      <ul className="my-8">
        <li className="mb-3">November X 2019: <span className="underline text-gray-600 hover:text-grey-900">How To Add Salesforce CMS</span>.</li>
        <li className="mb-3">October X 2019: <span className="underline text-gray-600 hover:text-grey-900">Creating A Simple Website With React.js & Tailwind CSS</span>.</li>
        <li className="mb-3"><span className="underline text-gray-600 hover:text-grey-900">Hi</span>.</li>
      </ul>
    </div>

  </div>
);

export default Notes;
