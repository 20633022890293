import React from 'react';

import {
  Link
} from 'react-router-dom';

const LiteraryCreatures = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Literary Creatures</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">September 2018 &bull; Philly Fringe Festival</h3>
        <h4 className="text-gray-300 text-2xl italic">a world-building collective for all ages</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">I</span>n 2014, I was 25,</span> crashing with a friend, working part-time at a bookstore in Princeton, and <a href="www.typewriterpoetry.com" target="_blank" rel="noopener noreferrer">typing free poetry for strangers</a>. I got a job art modeling for a sculpture class at PAFA, which meant I had to get up at 4am every morning to catch the Dinky&mdash;a little train that left campus and took you to the <em>real</em> station&mdash;and walk from 30th to Broad. I remember the dark early mornings, the anxiety as I ran, late, with my green backpacking bag to the station. The relief whenever I made it to 30th Street Station on time, the steaming chai lattes from Au Bon Pan I treated myself to whenever I had cash people gave me after I typed them a poem, the sonar resonance of the inside of the station, which held a grand but contained flow and hustle within it.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">That first morning, as I headed down JFK Blvd. Bridge, the sun struck. The colossal, iridescent reflections of each building took my breath away. "This is a city built from crystals," I thought, basking in the glow of orange, pink, blue, and yellow pastel blends.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Now, in 2018, one believes me when I tell them I moved here because I fell in love with the architecture. Not just the glass surfaces of the tallest buildings in the center of the city, but the ruins and the rubble, the rowhouses, the old West Philly homes.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">"Do you think Philly is a vortex?" I ask. Everyone hesitates, considers; stumbling over silence in ways that are new and exciting.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">This silence, this "yes, but&mdash;", this grappling is why I created <em>Literary Creatures</em>. It’s why there’s no other place where this performance could have existed&mdash;and why Philly, and the people I’ve loved contained within it&mdash;sit on one of the most potent, resistant, entangled magical conduits I’ve only ever experienced while in motion and traveling.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">The year was spent in quiet waiting.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Whenever friends would ask me what it was, I fumbled. "I think it’s going to be an art show. Something that combines Typewriter Poetry with sounds."</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">As summer shifted into fall, <em>Literary Creatures</em> tumbled miraculously into place. There were loose plans&mdash;very loose, like the soil of a newly burnt field, simply waiting for growth to come find it. An idea of black-light, of a synthesizer; of a typewriter poem and a room dressed in ethereal plantlife. After years of scattering tiny seeds of what Literary Creatures could be, I signed up for Philadelphia’s Fringe Festival. It felt on the surface like a whim pulled along by the project’s deeper desires: a desire to manifest, to remain fixed, to morph into a concrete experience; an event.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Literary Creatures was birthed and rebirthed at 10pm every Friday and Saturday night, running for three consecutive weekends from September 7th to the 23rd. Each night, I expected I would perform to an empty room. Each night, I was pleasantly surprised to find friends and strangers coming in to watch and participate in the show.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Some performances were long. We raged past the show and burrowed furiously into the night. Other performances were lonely and quiet, intimate, with soft conversation and relaxed acceptance of letting the flow be. On the last night, our voices rose and fell in play with one another, harmonizing at their own accord to the perfect pitches, intervals, frequencies.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">Sometimes, we lost touch.<br />We often quickly regained it.</p>

      <p className="block mx-auto my-8 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Each day of the show, I was a mess of anxious nerves, all bundled up like tangled Christmas lights. This nervous adrenaline, I would soon realize, was more than terrifying butterflies. It was the catapult&mdash;the integral part of the show. To charm these flutters, I entered into routine. Every night, I cleaned and set the stage with mindful intention and ritual: sweeping the floor, moving the chairs, inflating the air mattress, positioning the flowers, the black-lights, the threads&mdash;and of course, carefully unraveling the Christmas lights. Often, my friends would pitch in, arriving early to help and stay late to clean. I watched their own methods of preparation and was struck by the beauty in their graceful folding, the quickness in their steps.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">All night, we were confronted by the challenge to show up.<br />&mdash;(The nights were only won upon arrival.)</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">This was a ride&mdash;a test&mdash;to see if we could break through. Was Literary Creatures&mdash;was I, were we&mdash;was anything worthy of the magic that is serendipity, connection, chance? I didn’t know. But every show, I felt it&mdash;</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">a calm rush much like the sea, embracing each and every part of me</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">&mdash;a knowing that this was it, this was it, THIS WAS IT. All would be fine.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">And all was.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">In Literary Creatures, luck, chance, spontaneity, improvisation, connection, synergy, and flow are central to the work.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">An interactive and participatory performance art piece, a different show revealed itself each night. The set, like all of my work, gives the distinct impression that a kid has built it, perhaps on a hot summer day, after rummaging around in her parent’s garage for hours, collecting everything and anything that could be made of use. In Literary Creatures, I created a quirky, otherworldly set drawn from loosely acquired, found objects: an air-mattress dressed in Halloween and Christmas lights, fake but vibrant neon flowers, and a strange, glowing green impenetrable web spun from copious amounts of cotton.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The entire performance took place in this alien bathhouse. Constructing the set was a meditative act, often bleeding into the show, itself. In the vibrant romance of black-lights, neon face-paint, lime green webbing, and fluorescent flowers, the alien bathhouse’s true purpose emerged. It was the vehicle that helped encourage participants to let go of their tired, worn, everyday roles and breathe life into a new dimension based upon nostalgic, do-it-yourself, whimsy.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">I have always been a Resourceful Scavenger.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Our heroine, A. Ti Nyalien (pronounced "a tiny alien"), is a curious but soft-spoken, ethereal creature attempting to bridge the gap between reality and other-worldliness. She began each show by gracefully acknowledging each participant with silent panning and purposeful eye-contact. She played a distinctive melody made of synthesized bells&mdash;a short song with no words which later repeated itself as humming, voices, piano, bells, and absent-minded whistles throughout the show. Inevitably, a story would emerge&mdash;sometimes about snow, other times about whales, and even sea turtles&mdash;before inviting the audience to shout out their "sounds." As the show progressed, participants relaxed into a spirited mode of re/creation through musical instruments, voice, typewriter poetry, tactile vibrations, face-paint, thin rope, and intimate question & answers about connection, disconnection, loneliness, togetherness.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The vision of Literary Creatures was to inspire cathartic release through repetition, epiphany, intuition, instruction and consent, spontaneous creation, childlike wonder, and play.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Though seemingly passive and without narrative, Literary Creatures consisted of a structure brought upon the collection of found objects (and their placement), art and music therapy, improvised monologues, and the cultivation of everyday magics within mundane rituals&mdash;like cleaning.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Participants were often delighted by the rules of consent presented as an exchange of flowers. People were surprised and humbled by their own responsibility in sustaining a space’s spirit, a community’s flow. They talked afterward about the magic they were able to access within themselves, and expand upon deeper into the night. Someone said they were pleased watching me be free in a world built of my own making, through a character inspired by her own desires. In a subtle sense, Literary Creatures also delighted through its light suggestion of innocent, sexual energy and play, oscillating between quirky innocence (a bed as the bathhouse) and felt energy (tension among participants, and the potential for the night to expand endlessly, in any way, and in every direction).</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">Literary Creatures is a catharsis with intention and happenstance built upon intuition.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Trust in the magic of the moment was essential to the late night show’s ability to transform an old factory art loft into an intimate island of threaded connection floating leisurely through time and space. I do this work to reengage with reality. It is my core belief that inspiration can and must be fought for. The act of regaining an ancient memory of play in everyday living is a battle for reminders, and proof, that it is not only possible but lovely to tap back into this state of being. Though I inhabit multiple storylines and roles, my primary role as an artist is as Dedicated Will. By creating, challenging, and engaging in this way, I hope to cultivate, harvest, and spread the ever waning and waxing spirits of epiphany, magic, sensation, and memory.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The gift must be (re)distributed.</p>
      <p className="my-8 text-gray-500 italic text-4xl leading-relaxed">Long live the multiradicals,<br />for <Link to="/imagination-is-the-new-work"><span className="underline text-gray-600 hover:text-grey-900">Imagination is the New Work</span></Link>.</p>

      <p className="block mx-auto my-8 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Literary Creatures came into existence thanks to countless conversations and experiences with friends. Shoutout to all the friends I’ve made in Philadelphia who answered my question: "Is Philly a vortex?" with their honest, raw thoughts. Thank you to Jeff, a random coworker at a random job years ago who once sat on a bench in a park with me and told me he thinks everyone is aware of the mask which they wear. I’m still not sure if I agree. Thank you to friends and strangers who supported the work by purchasing tickets. Thank you train for brainstorming space + sound at strange intervals in the night. Thank you to everyone and anyone who held space for me as I verbally dreamed this production aloud. Thanks to repeat participants in the show&mdash;including _xandie&mdash;as well as all the first-timers who are already ready to come back. Last but not least, this tiny alien would like to thank to Leigh, aka the most solid contributor and dreamer to the project besides myself. Without your presence and insight, the Literary Creatures Philly Fringe Fest run would not have been. Thank you for the Mezcal, and for coming with me to find the perfect vintage amp. You shined your brilliance in the form of preacher songs, distinctive sounds, and on-the-spot crafting of a beautiful answer to my sad, sullen questions about sea turtles and light pollution.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">May the north star always linger. May the Middle C’s/Seas ring out into eternity.</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full">

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_01.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_02.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_03.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_04.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_05.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_06.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_07.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_08.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_09.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018_10.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-2018.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_literary-creatures_philly-fringe-fest-2018.png" alt="Sunset in the mountains" />
      </div>
    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-carnival" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Literary Carnival - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Carnival</div>
              <p className="italic font-bold text-gray-500 mb-2">August 2013, the Newer York</p>
              <p className="text-gray-700 text-base mb-2">
                Tarot, drag, &amp; <span className="italic">Typewriter Poetry</span> before the show.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2015)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
              <p className="text-gray-700 text-base mb-2">
                Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/princeton" className="work-card-link"></Link>
            <img style={{objectPosition: "top"}} src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Beyond Words - Princeton Public Library's Annual Gala - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry: Beyond Words Gala</div>
              <p className="italic font-bold text-gray-500 mb-2">December 2014, Princeton, NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Performing <span className="italic">Typewriter Poetry</span> during Princeton Public Library's annual fundraiser.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
);

export default LiteraryCreatures;
