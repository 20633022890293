import React from 'react';

const Website = () => (
  <div>
    <h2 className="text-3xl font-light text-gray-900 leading-none mb-6">Website Methodology &amp; Philosophy</h2>
    <div className="mt-0 mb-4 text-gray-600">
      <p className="my-3">The user will be able to recall what website or app they're using based upon visual identity & design context. (Therefore, if it does a good job, the logo / title does not need to be prominently displayed everywhere.)(What about inbound visitors who came in without the homepage?)</p>
      <p className="my-3">Responsive Masonry (instead of fixed heights, calculate height of screen;
        adjust accordingly). Solution: CSS variables & calc() function.</p>
      <p className="my-3">Iconography over information.</p>
      <p className="my-3">Tooltips for navigation.</p>
      <p className="my-3">Real-life animation physics. Example: bouncing ball (gravity's effect on the fall vs the force)</p>
      <p className="my-3">More app-like: Change header text depending on page; add back button in left corner</p>
      <p className="my-3">Icon Attribution: Vandelay Designs (pig)</p>
      <p className="my-3">Home as not just the introduction, but the place we all return to (a la music theory) => therefore, radial navigation (expand/contract) over linear (A => Z)</p>
    </div>
  </div>
);

export default Website;
