import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

class HomeFilter extends React.Component {

  constructor(props) {
    super(props);
  }

  tech(i) {

    const tech = document.querySelectorAll(".mobile-container .work-card.tech"),
          art = document.querySelectorAll(".mobile-container .work-card.art");
    for (var i = 0; i < tech.length; i++) {
      tech[i].classList.add('active');
      tech[i].classList.remove('hidden');
    }
    for (var i = 0; i < art.length; i++) {
      art[i].classList.add('hidden');
      art[i].classList.remove('active');
    }
  }

  art(i) {
    const tech = document.querySelectorAll(".mobile-container .work-card.tech"),
          art = document.querySelectorAll(".mobile-container .work-card.art");
    for (var i = 0; i < art.length; i++) {
      art[i].classList.add('active');
      art[i].classList.remove('hidden');
    }
    for (var i = 0; i < tech.length; i++) {
      tech[i].classList.add('hidden');
      tech[i].classList.remove('active');
    }
  }

  all(i) {
    const tech = document.querySelectorAll(".mobile-container .work-card.tech"),
          art = document.querySelectorAll(".mobile-container .work-card.art");
    for (var i = 0; i < art.length; i++) {
      art[i].classList.remove('active', 'hidden');
    }
    for (var i = 0; i < tech.length; i++) {
      tech[i].classList.remove('active', 'hidden');
    }
  }

  render() {

    return (

      <div className="filter-nav mt-8 flex mx-2 mx-auto items-center justify-between flex-wrap w-1/2 sm:w-1/4 text-gray-600">
        <div className="block text-center p-2 flex-inline md:p-4">
          <button id="tech" onClick={this.tech}>
            Tech
          </button>
        </div>
        <div className="block text-center p-2 flex-inline md:p-4">
          <button id="art" onClick={this.art}>
            Art
          </button>
        </div>
        <div className="block text-center p-2 flex-inline md:p-4">
          <button id="all" onClick={this.all}>
            All
          </button>
        </div>
      </div>
    );
  }
}

export default HomeFilter;
