import React from 'react';

import {
  Link
} from 'react-router-dom';

const PrincetonBeyondWords = () => (
  <div>

    <div className="bg-full" style={{backgroundImage: "url('./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4xl md:text-6xl">Free Poetry: Beyond Words</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">December 2014 &bull; Princeton, NJ</h3>
        <h4 className="text-gray-300 text-2xl italic">Free Poetry for Princeton Public Library's annual fundraising gala</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">P</span>rivate events have an allure</span> which public spaces lack. In both instances, you are a spectacle; yet there is a distinct difference in how people react to and approach you. Public spaces offer a nonchalant curiosity, but events open people up rather immediately. Everyone’s behavior is influenced by the fantastic, not-so-everyday surroundings and atmosphere.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">…plus, there’s alcohol.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Last month, I was invited to type at Princeton Library’s annual benefit, Beyond Words. These photographs were snapped by Melissa; you can find her work through her website.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Yamile, a volunteer organizer, had seen me typing free poems for people at the farmer’s market in front of the library. She left a message for me where I worked, asking if we could meet. Soon after, we got the chance to talk about the library and Typewriter Poetry. Performing for the Princeton Library benefit was a no-brainer, as it’s one of my favorite places in Princeton (besides the cemetery).</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I instantly fell in love with the theme for this year’s gala. The committee had conceived an evening mixed with greys, blacks, whites–all heightened by reds and book typography. I mentioned costumes and we came up with props, decorations, and layout. She wanted to know more about the act of pushing out poetry for a crowd. “There’s going to be over two hundred people,” she warned. “Can you keep up with that?”</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Typing for the author Gary Shteyngart was quite the experience. People kept coming up to exchange words with him, ask for an autograph, request a picture. He seemed skeptical but curious about the free poetry process. When he read his poem, I watched his face reveal quiet appreciation, a secret knowing passing between us.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The Beyond Words gala elevated Princeton University’s Frick Chemistry Lab into a literary wonderland reflected through timeless glass. Captivated by conversation, tapas and wine, attendees were enthralled by the night. Everyone bubbled in celebration of the Princeton Library, hazily taken away by live classical music, auctions, vendors, food, dancing, and free poetry. I’m honored Typewriter Poetry played a small role in the enchantment, even if it was for one night (and one night, only).</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full" style={{height:1350 + "px"}}>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016_01.jpg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016.jpg" alt="Typewriter Poetry - Free Poetry - Beyond Words - Princeton Public Library Annual Fundraising Gala - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016_02.jpg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_2016_03.jpg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_bai_2016.jpg" alt="Bai Drink - Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_christine-todd-whitman_governor-new-jersey_2016.jpg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_gary-shteyngart_2016_01.jpg" alt="Free Poetry" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./../img/billimarie-lubiano-robinson_typewriter-poetry_princeton-public-library-fundraiser_beyond-words_gary-shteyngart_2016.jpg" alt="Free Poetry" />
      </div>

    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/literary-carnival" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson-typewriter-poetry-literary-carnival-los-angeles-2013.jpg" alt="Literary Carnival - Typewriter Poetry - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Literary Carnival</div>
              <p className="italic font-bold text-gray-500 mb-2">August 2013, the Newer York</p>
              <p className="text-gray-700 text-base mb-2">
                Tarot, drag, &amp; <span className="italic">Typewriter Poetry</span> before the show.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/free-poetry/tesla-spacex" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_free-poetry_tesla-space-x_valentines-day-2018.jpg" alt="Typewriter Poetry - Tesla, SpaceX Valentine's Day - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2018)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2018, Tesla &amp; SpaceX</p>
              <p className="text-gray-700 text-base mb-2">
                Writing <span className="italic">Typewriter Poetry</span> for Tesla &amp; SpaceX employees on Valentine's Day.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./../img/billimarie-lubiano-robinson_typewriter-poetry_staten-island-museum_betty-bressi_2015.gif" alt="Typewriter Poetry - Betty Bressi, Staten Island Museum - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Free Poetry (2015)</div>
              <p className="italic font-bold text-gray-500 mb-2">February 2015, Staten Island Museum</p>
              <p className="text-gray-700 text-base mb-2">
                Typewriter Poetry performed in conjunction with the Betty Bressi Typewriter Lovefest exhibit.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default PrincetonBeyondWords;
