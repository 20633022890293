import React from 'react';

import {
  Link
} from 'react-router-dom';

const HopeworksWeb = () => (
  <div className="hopeworks-web">

    <div className="bg-full" style={{backgroundImage: "url('./img/billimarie-lubiano-robinson_hopeworks-web_spring-2019.jpg')"}}>
      <div className="dark-overlay"></div>
      <div className="bg-full-heading text-center z-10">
        <h2 className="text-white text-4x1 md:text-6xl">Hopeworks Web</h2>
        <h3 className="text-gray-100 text-3xl md:text-4xl">July 2018 - October 2019 &bull; Camden, NJ</h3>
        <h4 className="text-gray-300 text-2xl italic">Increasing sales through a culture of collaboration, diversity, &amp; playful engagement</h4>
      </div>
    </div>

    <div className="w-full mx-auto px-4 py-12 lg:px-8 lg:w-2/3 md:leading-relaxed lg:leading-loose text-justify">

      <p className="block mx-auto mt-6 mb-8 py-12 text-4xl text-center"><span role="img" aria-label="Long Live The Multiradicals">💖👾💖</span></p>

      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><span className="uppercase font-bold"><span className="dropcap">A</span>fter my first week as</span> the Web Development Director at Hopeworks, I jotted down a quick description of what I hoped to accomplish during my time there:</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>Cultivate a standardized dev/design environment</li>
        <li>Expand network of dev &amp; design mentors for interns</li>
        <li>100% placement of interns in a job/school after Hopeworks Web</li>
        <li>Increase baseline website sales price</li>
        <li>Build out processes for recruiting, hiring, onboarding, sustaining, &amp; exiting interns</li>
        <li>Learn business development &amp; financial analysis</li>
      </ul>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I tucked away the paper in my work journal, forgetting about it soon after.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">At the time, I anticipated the journey to take anywhere from two to five years. With the help of an incredible team of interns &amp; stellar mentorship &amp; teamwork from my boss, I was able to hit the majority of these personal metrics within one year.</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Cultivate a Standardized Dev/Design Environment</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It was important to me to mirror the experience of being a professional developer or designer, so that interns could go through the process having felt as though they were trained. As such, one of the first things I did was introduce and visualize standard project phases, so that everyone could have access to a common way of speaking without intricate dev/design jargon.</p>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Project Phases</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I made the decision to simplify &amp; break down the project cycle in the following ways:</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>Discovery</li>
        <li>Sitemap</li>
        <li>Wireframes</li>
        <li>Visual Designs</li>
        <li>Build</li>
        <li>QA/UAT</li>
        <li>Launch</li>
      </ul>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">By normalizing this project cycle, both the business department and the Hopeworks Web interns had a common language for helping clients launch engaging websites. Adding project phases to our <strong>Scope of Work</strong> helped the buisness department land sales &amp; analyze project statuses based upon phases. Creating a digital project board for the Hopeworks Web department meant interns could easily visualize where they were as an individual contributor as well as where the entire team was within the website funnel. This led to an increase in production &amp; transparent communication about website progress between all parties involved.</p>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">One-On-One Coding</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">My experience as a teacher for <a href="/coded-by-kids"><span className="underline text-gray-600 hover:text-grey-900">Coded by Kids</span></a> helped me remember the importance of one-on-one connections. Throughout the day, I'd randomly pick an intern &amp; pop over to their desk for a quick check-in. This practice was something I was extremely grateful for at my previous jobs as a developer: teammates randomly asking if you needed help with anything. Being able to pair-program in this way meant interns could retain lessons about best practices in UX/UI, front-end development, back-end development, client/project management, &amp; design&mdash;thus producing higher quality custom work.</p>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Weekly Dev Team Meetings</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">While I was a front-end developer at Message Agency, I really enjoyed the practice of team meetings. I brought along the concept to Hopeworks, reserving time on the calendar every week for a team debrief. It was incredibly useful for interns to have a space &amp; platform to voice opinions on departmental updates, projects, &amp; simply talking about cool coding &amp; design projects they came across over the weekend. Interns had full access to the weekly agenda, which most would update so they had a chance to talk about their dreams &amp; experiences beyond Hopeworks &amp; Hopeworks Web.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Half of the meetings were spent analyzing different coding &amp; design problems we came across the previous week. Being able to have miniature post-mortems about our work meant everyone could learn new methods for dealing with common problems, which led to higher team engagement &amp; vulnerability about the learning journey.</p>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Client Onboarding</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Every successful project manager knows that the number one driver of success for web projects is the quality of the client experience. Technical projects which fail to consider the client are often stonewalled by outside decision-makers feeling left out &amp; confused about the process.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">One of the first things I did was introduce myself to the old clients, &amp; work hard at fulfilling their requests. I didn't always succeed, but when I did, it felt great to be able to provide solutions along with friendly customer service.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">As I began picking up more &amp; more clients, it became obvious that a need for <strong>client education &amp; onboarding</strong> would be central to the success of the Hopeworks Web department. Similar to my relationship with the business department as well as with my interns, I utilized empathy &amp; overshared knowledge so that clients could feel like they were being taken care of, rather than merely tolerated. I often received the compliment that I was not "the typical developer"&mdash;in many ways, this is true. I enjoyed communicating in everyday, accessible language the nuances of technical decisions, website logic, &amp; design systems. It's part of the reason I love teaching. Words can either be the wall that separates us, or the common thread that binds us. I personally prefer the latter.</p>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Online Portfolio</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">A few months into my role, I pitched an original &amp; initial concept called HopeworksWeb.com. The idea was to create a website specifically for the Hopeworks Web department, treating it in a similar vein to dev &amp; design agencies. I built, designed, &amp; maintained the website as a sales, marketing, &amp; portfolio tool. It included links to websites our interns had created for clients, a testimonial section, a contact form for lead generation, &amp; a nice write-up about each member of the team. The website’s success created ripple effects in other business departments, later spawning other departmental plans for a HopeworksGIS &amp; HopeworksHealing iteration.</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Increase Website Sales Price</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The quote for a custom WordPress website at Hopeworks was deeply below market rate. The first thing I set out to do was challenge every price the Business Director & Executive Director came up with. This is where knowing your market helps tremendously: when you know the value of something, it becomes fact, not opinion. You can map out a process for elevating value based upon your experiences with the market as it shifts.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I created thorough documentation on the types of websites &amp; website products we could offer internally, as well as the components of each. For instance, if a client wanted "a simple website for people to sign up for a mailing list since we're not ready to go live," I encouraged our sales team to pitch the product as a <span className="font-bold">Landing Page</span> with a simple <span className="font-bold">Call to Action</span> to sign up via an elegant <span className="font-bold">Form</span> that will subscribe users automatically to a <span className="font-bold">Mailing List</span>. Coming from a developer &amp; designer background meant it was easy to break down these components, adding value to the sales conversation which would ultimately translate in the client knowing they were paying for something more than just "a simple website."</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl"><strong>By the end of the fiscal year, the median base for website pricing increased by 200%</strong>. This is due to the business department &amp; executive director's sales abilities&mdash;all while being backed up by evidence, elegance, &amp; engagement by the Hopeworks Web team.</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Expand Network of Dev &amp; Design Mentors</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I know of no industry where relationships &amp; networking aren't important. (If you do, please let me know, as I am 100% introverted &amp; would love the opportunity to disconnect from this exhausting ritual!)</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">In some households &amp; schools, kids get to see "networking" modeled out in a normal context. In others, the concept &amp; practice is completely foreign. When I was younger, I burned a lot of bridges simply because I didn't know &amp; failed to grasp these types of rules of engagement. I didn't want my interns to make the same mistakes I did due to a lack of practice, modeling, &amp; know-how. I pushed to normalize the process of following up with emails, scheduling coffee dates, going to events, &amp; requesting connections on LinkedIn&mdash;all the while trying to get better at those things, myself.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The flow of daily tours at Hopeworks is a wonderful opportunity for interns to practice their networking skills. During the Hopeworks Web portion of the tour, I made it canon to focus on interns rather than myself. I would quickly identify myself as the department director (after one guest said I "didn't look old enough to listen to NPR"), then shift the conversation to a random Hopeworks Web intern. That intern would stand up &amp; walk the guests through our project phases, &amp; answer questions about their experience in the program. Often times it turned into a group discussion with the entire team. This meant everyone got the chance to get the guests' contact information, which meant everyone had the chance to quickly follow up and connect.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">One of the first things I did was set up a Slack channel for our team. I called it <em>Hopeworks Web</em>&mdash;the name stuck for the department as a whole. Whenever I would meet other developers, designers, project managers, or anyone working at a tech company, I'd invite them to join our Slack channel. That way, they could chime in on group threads about bug fixes&mdash;they could also be directly available for interns to message, equalizing the barrier of communication in a way email often can't.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Thanks to my previous relationships in the tech industry, I was able to connect a lot of interns to personal friends &amp; colleagues doing incredible work as developers, designers, &amp; project managers. I pushed to coordinate Hopeworks Web outings to tech events in Philly, &amp; encouraged the team to go to events as individuals as well as with their peers. My favorite trip was to Princeton's StudioLab. The team had the chance to tour their makerspace, &amp; play around with some equipment. Most importantly, they had the chance to sit down over lunch and ask questions, talk about themselves, &amp; learn from my friends &amp; collegues, <a href="https://aatishb.com" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">Aatish</span></a> &amp; <a href="https://unoseistres.com/" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">Sharon</span></a> (the StudioLab leadership at the time).</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Build Out Processes for Recruiting, Hiring, Onboarding, Sustaining, &amp; Exiting Interns</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">As a documentation nerd (<span role="img" aria-label="Heh">🤓</span>), my pride &amp; glory remains in the thorough Hopeworks Web Manual I created for the next Director. I started it when I realized the system I implemented was working better than expected. I fall into the typical millenial stereotype which dictates we look for meaningful work at all costs&mdash;for me, that means if I can improve a space/place up to the point it can function without me, it's time to look for a new puzzle to solve.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It took months for me to complete the departmental manual, &amp; it required a mental shift as I thought about what staff members could be added to the team, as well as who would replace me.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The first piece in this incricate puzzle was developing a professionalized recruitment &amp; hiring process. I took directly from my experiences as a developer &amp; designer &amp; initiated a phase-by-phase "intern journey":</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>Application</li>
        <li>Team Interview</li>
        <li>Technical Interview</li>
        <li>Onboarding</li>
        <li>Sustaining</li>
        <li>Exiting</li>
      </ul>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Application</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Originally, there was no application an intern needed to fill out in order to be considered for an internship. This meant the flow was very casual: an intern simply emailed the department head about availability, &amp; an interview was set up.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Easy, right? Unfortunately, there are little to no jobs in "the real world" which operate this way. More than likely, if you do not have any connections to anyone at the organization you're applying to, you'll have to fill out an online form and wait for a response.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I mirrored this application process because I knew it would be what interns would face once they left Hopeworks for good. Part of my personal mission was to normalize the gatekeeping methods of the tech industry so that trainees &amp; interns could rise up &amp; practice confronting them in a safe environment.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I used Typeform to create a simple Hopeworks Web online application to quickly discern which candidates were serious. It consisted of the following questions:</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>What's your contact information?</li>
        <li>What does your schedule &amp; availability look like?</li>
        <li>What attracts you to code &amp;/or design?</li>
        <li>Rate your HTML, CSS, &amp; JavaScript abilities (1 out of 5).</li>
        <li>What do you hope to accomplish after your time as a Hopeworks Web intern?</li>
      </ul>
      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Team Interview</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Once a trainee submitted their online application to become a Hopeworks Web intern, I invited them for a team interview. As a developer, I was often interviewed by teams rather than individuals. There's a lot of interesting discussion around the methodology behind group vs. one-on-one interviewing, but for my purposes, I went with a team interview for two reasons:</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>The candidate would feel less pressure being interviewed by peers. That means more honesty, which means more practice.</li>
        <li>The current team would step up &amp; take initiative as a collective group of leaders.</li>
      </ul>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I set the team up by giving them a list of questions to ask, as well as internal notes for why the question was important. This meant they understood the motivation as it related to their everyday jobs, &amp; could listen for variations of a type of response.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">The team would return from the interview with their individual notes, typed up for me to review. Every group of interns I had who went through this process absolutely stunned me with their insightful comments. Their mindful screening helped me choose who would go onto the next phase&mdash;the Technical Interview&mdash;and who would not.</p>

      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Technical Interview</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">It took me a few tries to come up with the perfect technical challenge for novice developers &amp; designers. In the end, I decided to go with two types of tests, depending on the candidate's portfolio:</p>
      <ul className="my-8 ml-6 text-gray-700 text-2xl lg:text-1xl list-disc">
        <li>A simple button test.</li>
        <li>A code mock-up of a header navigation, based upon a random Behance design.</li>
      </ul>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I was inspired by the A List Apart article by Erik Kennedy, <a href="https://alistapart.com/article/the-king-vs-pawn-game-of-ui-design/" target="_blank" rel="noopener noreferrer"><span className="underline text-gray-600 hover:text-grey-900">The King vs. Pawn Game of UI Design</span></a>, where the author uses the example of chess to describe his theory of buttons being the backbone of a design system. I sat with the candidate one-on-one in front of a laptop, &amp; asked them to create a button. You can tell a lot about a person &amp; how much of the fundamentals they grasp by watching them code, style, classify, organize, &amp; animate something as simple as a button.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">When the button test became common knowledge amongst candidates, I switched to a more traditional challenge: to the best of your abilities, construct a navigation menu by following a random design on Behance.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Unlike a dev or design agency, I was not looking for talent&mdash;although it was nice when I came across it! I was looking for candidates who were intuitive, could follow directions, weren't defensive, &amp; could quickly grasp solutions once they were explained in an easy-to-understand way.</p>

      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Onboarding</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Setting up new interns for success was something I enjoyed puzzling over & hammering out. As an extremely introverted individual who is often times made invisible in group settings, I place a high priority on methods of engagement which loop all individuals in regardless of personality, skill, &amp; history. As such, I wanted to create a basic onboarding plan which helped interns remain anchored to the journey, rather than overwhelmed with new responsibilities, demands, &amp; skills. I'm incredibly grateful for all the intern's feedback on this process, as it helped hone it into what it is today.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Like any dev shop or design agency, the first step was simplifying which accounts to sign up for &amp; which programs to download. It came down to tools like GitHub, CodePen, Asana, &amp; Toggl. I assigned very easy tasks the first day for initiating projects, creating project boards, &amp; learning these tools so that new interns would understand the expectation to use them frequently throughout the remainder of their internship.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Early during my time at Hopeworks, I was inspired by our life readiness department's <strong>"DREAMS" template</strong> which thoughtfully encouraged trainees to consider their dreams for the future. I adapted it to Hopeworks Web so that interns could hyperfocus on their personal goals &amp; dreams in tech: what they hope to accomplish in 3 months, 6 months, & 1 year&mdash;as well as a final section on the ultimate end-game. Interns would share theirs during our team meeting. We would often return to what they wrote their first day of the internship as an anchor &amp; guiding post for future success.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">As interns progressed during their first two weeks, Client Work vs. Personal Portfolio. Independent Research. Maintenance.</p>

      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Sustaining</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">As interns progressed throughout their internship, an inevitable slump would occur as the novelty wore off. I considered it part of my job to keep them engaged by introducing new aspects of the journey. One particular method I enjoyed was encouraging them to <strong>mentor</strong> other interns. When an intern experienced the positive feedback loop of allowing another intern to shadow them, it restimulated their day-to-day. This was helpful to not only the experienced intern, but the new intern being onboarded, as well. It was also helpful to me as the director, because it meant I could check-in less &amp; depend on each intern to provide peer-to-peer support when needed.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Beyond shadowing &amp; mentoring were <strong>leadership opportunities</strong>. Once an intern began nearing the end of their Hopeworks Web journey, I leaned heavily on pointing out various leadership qualities they demonstrated throughout the week, as well as encouraging them to embrace leadership opportunities in the future. Whether that was in Hopeworks Web as a lead designer or developer, during the increible entrepreneurship classes our board president Brad Aaronson led, or throughout the Hopeworks organization as a speaker or representative at a funder's event, I'm happy to have witnessed the growth of each of my interns as they stepped outside of their comfort zone &amp; embraced opportunities for speaking in public, leading projects with expertise &amp; hard work, or stepping out of their shell to share the amazing projects they were working on.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I'm a fan of how one-on-one supervisions are structured at Hopeworks. It offered a chance to dive into intern issues outside of a group context. The biggest challenge for me was figuring out how to maintain accurate boundaries. I wanted to remain open &amp; helpful, but more importantly I wanted to help in the realm of tech professionalism &amp; skill-building. Though I did figure out useful techniques for redirecting the conversation, I never did come up with a tool for keeping the conversation purely professional&mdash;I'm still working on this, today.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Part of what I envisioned the internship to be was a place of practice for building skills, interviewing, team collaboration, &amp; client professionalism. One of my favorite areas was the <strong>walkthrough</strong>. After an intern completed a project milestone (like a Sitemap or a Wireframe), I would ask them to prepare to present it to the team for critique &amp; feedback the next day. Every intern was extremely nervous about presenting; every intern was extremely supportive of the people presenting. Interns had the chance to practice communicating their design &amp; dev decisions while receiving feedback in a safe space. I considered it my role to play devil's advocate &amp; ask pointed questions pressing into why they chose to design or code something in a specific way. These walkthroughs were practice for the next day's client meeting, where the intern would step up to the plate &amp; walk the client through their recent deliverable.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I knew I had done my job right when I could confidently leave interns to <strong>independently run their own client meetings</strong>. One lesson I learned early in this process was that I had to judge clients harshly to determine who would be the perfect fit for an intern. As experienced business leaders, clients could easily take advantage of intern inexperience &amp; get them to commit to work without my knowledge. This was a hard lesson to learn &amp; one that made me extremely angry, but I'm happy to say it encouraged me to come up with massive boundaries between clients &amp; interns for the remainder of my time as the director.</p>

      <p className="block mx-auto my-6 py-6 text-3xl text-bold text-gray-600">Exiting</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">My idealistic goal was to get every intern an entry-level job at a dev or design shop in Philly. Part of the extiting process for Hopeworks Web was applying to jobs, interviewing, connecting with outside mentors, &amp; finishing their portfolio.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Like the one-on-one supervisions, <strong>the exit interview</strong> was an incredible tool for staff to receive feedback. I asked all exiting interns for opinions &amp; perspectives that they wish they knew during their internship, or supports they wish they received during their time in Hopeworks Web. As with all things, I was incredibly humbled by the thoughtfulness of each intern, &amp; their ability to be honest &amp; forthright in their critique of the department &amp; internship as a whole. I honestly believe the Hopeworks Web internship thrived because of the invaluable feedback I had the chance to implement after every intern shared their journey with me as they left.</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700"><span className="strike">100%</span> 90% Placement of Interns in a Job/School After Hopeworks Web</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">For some trainees and interns, Hopeworks can be a comfort zone one never wants to leave. It was important to me to enforce a culture within Hopeworks Web to view the internship as a stepping stone on a path with "way more awesome experiences" up ahead. I gently enforced this during every weekly team meeting, every one-on-one supervision. I like to think this helped instill a quiet confidence in each person on my team as they began to view the internship not as the point of "arrival," but as the point for being challenged to independently grow.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">90% of interns went on to a job or enrolled in classes after completing their internship. I'm still very proud of each one of them, and continue to wish for their success.</p>

      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Learn Business Development &amp; Financial Analysis</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">Everything I've written up until now came very easy to me due to my past experiences as a teacher, developer, &amp; designer. From mentoring interns to managing multiple client projects, from providing stellar customer service to balancing back-end database management &amp; website launches with daily technical work, forever lurking in the shadows was my fear of business development &amp; financial analysis&mdash;an entirely new realm I had only seen from the outside, but had very little experience in.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I remember being a bundle of nerves the first time I had to present to staff the financials of Hopeworks Web for the month. By shadowing the business director &amp; the executive director &amp; carefully listening to everyone else present their numbers, I managed to try out business financial language, the words feeling strange coming out of my mouth.</p>
      <p className="my-8 text-gray-700 text-2xl lg:text-1xl">I'm incredibly grateful for the challenge of falling in love with storytelling through numbers. From being nominated to participate in the Points of Lights workshops for social entrepreneurship, to feeling encouraged enough to ask the business director &amp; executive director what I can only assume were repetitive questions about ROI's, spreadsheet formulas, &amp; the underlying theory of financial projections, the most challenging part of being the Hopeworks Web Development Director was, for me, ultimately the path to incredible growth. I'm thankful for those opportunities to understand &amp; practice a bit of the fundamentals of business development, &amp; like all my work, hope to integrate its intricate threads in the next project I take on.</p>
    </div>

    <div className="masonry masonry--v mx-auto px-6 w-full">

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web-presentation_summer-2019.jpg" alt="Hopeworks Web - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_2019_01.jpg" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_epam_2019.JPG" alt="Hopeworks Web - EPAM - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_spring-2019.jpg" alt="Hopeworks Web - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_summer-2019.png" alt="Sunset in the mountains" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_social-enterprise-website_2019.jpg" alt="Hopeworks Web: the first website for a Hopework's business - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_gis_summer-2019.jpg" alt="Hopeworks Web - Summer 2019 - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_northstar_summer-2018.JPG" alt="Hopeworks - Summer 2018 - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_northstar-speaker_summer-2018.JPG" alt="Hopeworks - Northstar Panel - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-last-day_summer-2019.jpg" alt="Hopeworks - Spring 2019 - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks_philly-tech-week-diversity-dinner_2018.jpg" alt="Hopeworks - Philly Tech Week, 2018 - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-entrepreneur-class_recphilly_2019.jpg" alt="Hopeworks - RecPhilly - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_2019.PNG" alt="Hopeworks" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_desk_2018.JPG" alt="Hopeworks Web - Billimarie Lubiano Robinson" />
      </div>

      <div className="bg-white mx-4 mb-10 rounded overflow-hidden shadow-lg masonry-brick masonry-brick--v">
        <img className="w-full" src="./img/billimarie-lubiano-robinson_hopeworks-web_escape-room_spring-2019.jpg" alt="Hopeworks - Spring 2019 - Billimarie Lubiano Robinson" />
      </div>

    </div>

    <div className="my-12 mx-auto">
      <p className="block mx-auto my-12 py-12 text-4xl text-center text-gray-700">Related Projects</p>
      <div className="flex flex-col lg:px-2 md:inline-block lg:w-full lg:mx-auto">

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coded-by-kids" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_coded-by-kids_ctrl-shift-mentor_2018.jpg" alt="Coded by Kids - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Coded by Kids</div>
              <p className="italic font-bold text-gray-500 mb-2">March 2017 - June 2019, Philadelphia PA</p>
              <p className="text-gray-700 text-base mb-2">
                Taught high school and elementary students how to design &amp; code from scratch in classrooms throughout Philadelphia and Camden.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_stem-diversity-podcast_message-agency_july-2017.jpg" alt="Bringing Nonprofits to the Digital World - Billimarie Lubiano Robinson" />
            <div className="p-4">
              <div className="font-bold text-xl">Bringing Nonprofits to the Digital World</div>
              <p className="italic font-bold text-gray-500 mb-2">July 2017, STEM Diversity Podcast</p>
              <p className="text-gray-700 text-base mb-2">
                An interview on the Diversity in STEM podcast about helping nonprofits through tech.
              </p>
            </div>
          </div>
        </div>

        <div className="work-card px-4 py-12">
          <div className="bg-white rounded shadow-lg">
            <Link to="/coming-soon" className="work-card-link"></Link>
            <img src="./img/billimarie-lubiano-robinson_clasp_2016.jpg" alt="CLASP - Community Legal & Advocacy Services Project - Billimarie Lubiano Robinson - Northridge Review" />
            <div className="p-4">
              <div className="font-bold text-xl">CLASP</div>
              <p className="italic font-bold text-gray-500 mb-2">2016 - 2017, Princeton NJ</p>
              <p className="text-gray-700 text-base mb-2">
                Co-Founder & Director of Technology for the Community Legal & Advocacy Services Project, a nonprofit project advocating for prisoners' rights across prisons in New Jersey.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
);

export default HopeworksWeb;
