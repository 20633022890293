import React from 'react';
import {
  Switch,
  Route,
  Link
} from 'react-router-dom';

const Header = () => (
  <header className="w-full border-b border-grey bg-gray-100 p-2 lg:p-4 fixed z-10 top-0">
      <div className="flex items-center justify-between flex-wrap">
        <div className="block lg:hidden items-center flex-shrink text-white mx-auto lg:mr-6 lg:ml-0">
          <Switch>
            <Route exact path="/">
              <svg className="opacity-0 icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
              </svg>
            </Route>
            <Route exact path="/about">
              <Link to="/">
                <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                </svg>
              </Link>
            </Route>
            <Route exact path="/notes">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/contact">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/literary-creatures">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/evensongs">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/literary-carnival">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/hopeworks-web">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/coded-by-kids">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/free-poetry/tesla-spacex">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/free-poetry/princeton">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/imagination-is-the-new-work">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
            <Route exact path="/coming-soon">
                <Link to="/">
                  <svg className="icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
                  </svg>
                </Link>
            </Route>
          </Switch>
        </div>
        <div className="items-center flex-grow text-center lg:text-left text-white mx-auto lg:mr-6 lg:ml-2 lg:w-1/2">
          <Switch>
            <Route exact path="/">
              <h1 className="hidden md:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
              <h1 className="inline-block md:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Billimarie</h1>
            </Route>
            <Route exact path="/about">
              <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
              <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">About</h1>
            </Route>
            <Route exact path="/notes">
              <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
              <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Notes</h1>
            </Route>
            <Route exact path="/contact">
              <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
              <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Contact</h1>
            </Route>
              <Route exact path="/literary-creatures">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Literary Creatures</h1>
              </Route>
              <Route exact path="/evensongs">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Evensongs</h1>
              </Route>
              <Route exact path="/literary-carnival">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Literary Carnival</h1>
              </Route>
              <Route exact path="/imagination-is-the-new-work">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Imagination Is The New Work</h1>
              </Route>
              <Route exact path="/hopeworks-web">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Hopeworks Web</h1>
              </Route>
              <Route exact path="/coded-by-kids">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Coded By Kids</h1>
              </Route>
              <Route exact path="/free-poetry/tesla-spacex">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Free Poetry Tesla/SpaceX</h1>
              </Route>
              <Route exact path="/free-poetry/princeton">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Free Poetry Princeton</h1>
              </Route>
              <Route exact path="/coming-soon">
                <h1 className="hidden lg:inline-block font-bold uppercase text-2xl tracking-tight text-gray-900"><Link to="/">Billimarie Lubiano Robinson</Link></h1>
                <h1 className="inline-block lg:hidden font-bold uppercase text-2xl tracking-tight text-gray-900">Coming Soon</h1>
              </Route>
          </Switch>
        </div>
        <div className="block lg:hidden items-center flex-shrink text-white mx-auto lg:mr-6 lg:ml-0">
          <svg className="opacity-0 icon-back ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/>
          </svg>
        </div>
        <div className="hidden lg:block items-center flex-shrink mr-0">
          <ul className="icon-nav flex">
            <li className="text-gray-700 text-center px-10 py-2 m-2 flex justify-center">
              <Link to="/" data-tooltip="Home">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path className="text-gray-400 fill-current" d="M3 6l9 4v12l-9-4V6zm14-3v2c0 1.1-2.24 2-5 2s-5-.9-5-2V3c0 1.1 2.24 2 5 2s5-.9 5-2z"></path>
                  <polygon className="text-gray-700 fill-current" points="21 6 12 10 12 22 21 18"></polygon>
                </svg>
              </Link>
              <span data-tooltip="Home"></span>
            </li>
            <li className="text-gray-700 text-center px-10 py-2 m-2 mr-4 pr-0 flex justify-center">
              <Link to="/about" data-tooltip="About">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path className="text-gray-400 fill-current" d="M12 21a2 2 0 0 1-1.41-.59l-.83-.82A2 2 0 0 0 8.34 19H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a5 5 0 0 1 4 2v16z"></path>
                  <path className="text-gray-700 fill-current" d="M12 21V5a5 5 0 0 1 4-2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-4.34a2 2 0 0 0-1.42.59l-.83.82A2 2 0 0 1 12 21z"></path>
                </svg>
              </Link>
              <span data-tooltip="About"></span>
            </li>
          </ul>
        </div>
      </div>
  </header>
);

export default Header;
